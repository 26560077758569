export default {

  registrations: [],
  registration: {},
  registrationFiles: [],

  messages: [],
  closedMessages: [],
  message: {},

  notifications: [],
  splResult: null,

};
