import axios from 'axios'
import * as messages from '@/plugins/AlertMessages.js'

export default {

// Registration Requests and Files Actions -----------------------------------------------
getRegistrations({ dispatch, commit }) {
  commit(
    "setLoading",
    { status: 1, message: "Loading ..." },
    { root: true }
  );
  axios
    .get("registration")
    .then((res) => {
      commit("setRegistrations", res.data.data);
    })
    .catch((error) => {
      dispatch("errorGETHandler", error, { root: true });
    })
    .finally(() => {
      setTimeout(() => {
        commit("setLoading", { status: 0, message: "" }, { root: true });
      }, 500);
    });
},

getRegistration({ dispatch, commit }, data) {
  commit(
    "setLoading",
    { status: 1, message: "Loading ..." },
    { root: true }
  );
  axios
    .get("registration/" + data)
    .then((res) => {
      commit("setRegistration", res.data.data);
    })
    .catch((error) => {
      dispatch("errorGETHandler", error, { root: true });
    })
    .finally(() => {
      setTimeout(() => {
        commit("setLoading", { status: 0, message: "" }, { root: true });
      }, 500);
    });
},

addRegistration({ dispatch }, data) {
  axios
    .post("registration", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => {
      dispatch(
        "successPOSTHandler",
        {
          server: res,
          message: messages.alert.success.submit,
          redirect: false,
        },
        { root: true }
      );
    })
    .catch((error) => {
      dispatch("errorPOSTHandler", error, { root: true });
    });
},

editRegistration({ dispatch }, data) {
  axios
    .post("registration/" + data.id, data.value, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => {
      dispatch(
        "successPOSTHandler",
        {
          server: res,
          message: messages.alert.success.edit,
          redirect: false,
        },
        { root: true }
      );
    })
    .catch((error) => {
      dispatch("errorPOSTHandler", error, { root: true });
    });
},

changeRegistrationStatus({ dispatch }, data) {
  axios
    .put("registration/status/" + data.id, data.value)
    .then((res) => {
      dispatch(
        "successPOSTHandler",
        {
          server: res,
          message: messages.alert.success.changeStatus,
          redirect: false,
        },
        { root: true }
      );
    })
    .catch((error) => {
      dispatch("errorPOSTHandler", error, { root: true });
    });
},

getRegistrationFiles({ dispatch, commit }, data) {
  axios
    .get("registration/files/" + data)
    .then((res) => {
      commit("setRegistrationFiles", res.data.data);
    })
    .catch((error) => {
      dispatch("errorGETHandler", error, { root: true });
    })
},

addRegistrationFile({ dispatch }, data) {
  axios
    .post("registration/files", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => {
      dispatch(
        "successPOSTHandler",
        {
          server: res,
          message: messages.alert.success.upload,
          redirect: false,
        },
        { root: true }
      );
    })
    .catch((error) => {
      dispatch("errorPOSTHandler", error, { root: true });
    });
},

editRegistrationFile({ dispatch }, data) {
  axios
    .post("registration/files/" + data.id, data.value, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => {
      dispatch(
        "successPOSTHandler",
        {
          server: res,
          message: messages.alert.success.upload,
          redirect: false,
        },
        { root: true }
      );
    })
    .catch((error) => {
      dispatch("errorPOSTHandler", error, { root: true });
    });
},

// Messages and Tickets Action ---------------------------------------------------
getMessages({ dispatch, commit }) {
  commit(
    "setLoading",
    { status: 1, message: "Loading ..." },
    { root: true }
  );
  axios
    .get("messages")
    .then((res) => {
      commit("setMessages", res.data.data);
    })
    .catch((error) => {
      dispatch("errorGETHandler", error, { root: true });
    })
    .finally(() => {
      setTimeout(() => {
        commit("setLoading", { status: 0, message: "" }, { root: true });
      }, 500);
    });
},

getClosedMessages({ dispatch, commit }) {
  axios
    .get("messages/close")
    .then((res) => {
      commit("setClosedMessages", res.data.data);
    })
    .catch((error) => {
      dispatch("errorGETHandler", error, { root: true });
    });
},

getMessage({ dispatch, commit }, data) {
  commit(
    "setLoading",
    { status: 1, message: "Loading ..." },
    { root: true }
  );
  axios
    .get("messages/" + data)
    .then((res) => {
      commit("setMessage", res.data.data);
    })
    .catch((error) => {
      dispatch("errorGETHandler", error, { root: true });
    })
    .finally(() => {
      setTimeout(() => {
        commit("setLoading", { status: 0, message: "" }, { root: true });
      }, 500);
    });
},

addMessage({ dispatch }, data) {
  axios
    .post("messages", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => {
      dispatch(
        "successPOSTHandler",
        {
          server: res,
          message: messages.alert.success.submit,
          redirect: false,
        },
        { root: true }
      );
    })
    .catch((error) => {
      dispatch("errorPOSTHandler", error, { root: true });
    });
},

editMessage({ dispatch }, data) {
  axios
    .post("messages/" + data.id, data.value, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => {
      dispatch(
        "successPOSTHandler",
        {
          server: res,
          message: messages.alert.success.edit,
          redirect: false,
        },
        { root: true }
      );
    })
    .catch((error) => {
      dispatch("errorPOSTHandler", error, { root: true });
    });
},

closeMessage({ dispatch }, data) {
  axios
    .put("messages/close/" + data)
    .then((res) => {
      dispatch(
        "successPOSTHandler",
        {
          server: res,
          message: messages.alert.success.closeMessage,
          redirect: false,
        },
        { root: true }
      );
    })
    .catch((error) => {
      dispatch("errorPOSTHandler", error, { root: true });
    });
},

deleteMessage({ dispatch }, data) {
  axios
    .delete("messages/" + data)
    .then((res) => {
      dispatch(
        "successPOSTHandler",
        {
          server: res,
          message: messages.alert.success.delete,
          redirect: false,
        },
        { root: true }
      );
    })
    .catch((error) => {
      dispatch("errorPOSTHandler", error, { root: true });
    });
},


// Notifications Action ---------------------------------------------------
getNotifications({ dispatch, commit }) {
  axios
    .get("notifications")
    .then((res) => {
      commit("setNotifications", res.data.data);
    })
    .catch((error) => {
      dispatch("errorGETHandler", error, { root: true });
    });
},

changeNotificationStatus({ dispatch }, data) {
  axios
    .put("notifications/" + data)
    .then((res) => {
      dispatch(
        "successPOSTHandler",
        {
          server: res,
          message: null,
          redirect: false,
        },
        { root: true }
      );
    })
    .catch((error) => {
      dispatch("errorPOSTHandler", error, { root: true });
    });
},

// SPL Automation Actions
checkSPLAutomatic({ dispatch, commit }, data) {
  axios
    .get("spl/scraper?name=" + data.name + "&company=" + data.company)
    .then((res) => {
      commit("setSplResult", res.data.data);
    })
    .catch((error) => {
      dispatch("errorGETHandler", error, { root: true });
    })
},



};
