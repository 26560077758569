import axios from "axios";
import router from "@/router";
import * as messages from "@/plugins/AlertMessages.js";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.token;
axios.defaults.headers.common["Content-Type"] = "application/json";

export default {

  operatorLogin({ dispatch, commit }, data) {
    commit("setLoginCheck", true);
    axios
      .post("login/operator", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        commit('authUser', res.data.data);
        localStorage.setItem('token', res.data.data.token);
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + res.data.data.token;
        router.push({ name: "OperatorDashboard" });
        commit("setSuccess", messages.alert.success.login, { root: true });
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoginCheck", false);
        }, 800);
      });
  },

  login({ dispatch, commit }, data) {
    commit("setLoginCheck", true);
    axios
      .post("login", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        commit('authUser', res.data.data);
        localStorage.setItem('token', res.data.data.token);
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + res.data.data.token;
        router.push({ name: "Dashboard" });
        setTimeout(()=>{
          commit("setSuccess", messages.alert.success.login, { root: true });
        },800);
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoginCheck", false);
        }, 800);
      });
  },

  register({ dispatch, commit }, data) {
    commit("setLoginCheck", true);
    axios
    .post('register', data)
    .then(res => {
        commit('authUser', res.data.data);
        localStorage.setItem('token', res.data.data.token);
        localStorage.setItem('user', res.data.data.user);
        axios.defaults.headers.common["Authorization"] =
        "Bearer " + res.data.data.token;
        router.push({ name: 'Dashboard' });
        setTimeout(()=>{
          commit('setSuccess', res.data.message, { root : true });
        },1000);
      })
    .catch(error =>{
      dispatch('errorPOSTHandler', error)
    })
    .finally(() => {
      setTimeout(() => {
        commit("setLoginCheck", false);
      }, 800);
    });
  },

  resetPassword({ dispatch }, data) {
    axios
      .put("resetPassword", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.resetPassword,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  forgotPassword({ dispatch, commit }, data) {
    commit('setForgotBtn', true);
    axios
      .put("forgotPassword", data)
      .then((res) => {
        commit('setForgotCheck', false);
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.forgotPassword,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit('setForgotBtn', false);
        }, 1000);
      });
  },

  logout({ commit }) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    axios.defaults.headers.common["Authorization"] = "";
    commit("clearAuth");
    router.push({ name: "OperatorLogin" });
  },

  logoutCustomer({ commit }) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    axios.defaults.headers.common["Authorization"] = "";
    commit("clearAuth");
    router.push({ name: "CustomerLogin" });
  },

  getCurrentUser({ dispatch, commit }) {
    axios
    .get('profile')
    .then(res => {
      commit('setCurrentUser', res.data.data)
    })
    .catch(error => {
      dispatch('errorGETHandler', error, {root: true})
    })
  },

  editProfile({ dispatch }, data) {
    axios
    .post("profile", data, {headers: {'Content-Type': 'multipart/form-data'}})
    .then(res => {
      dispatch('successPOSTHandler',
      {server: res,
       message: messages.alert.success.editProfile,
       redirect: false},
       {root: true})
    })
    .catch(error => {
      dispatch('errorPOSTHandler', error, {root:true});
    })
  },

  editPassword({ dispatch }, data) {
    axios
    .post("profile/ChangePassword", data, {headers: {'Content-Type': 'multipart/form-data'}})
    .then(res => {
      dispatch('successPOSTHandler',
      {server: res,
       message: messages.alert.success.changePassword,
       redirect: false},
       {root: true})
    })
    .catch(error => {
      dispatch('errorPOSTHandler', error, {root:true});
    })
  },

  // Alert and Errors Handler ------------------------------------------------------
  successPOSTHandler({ commit }, data) {
    if (data.server && data.server.status === 200) {
      if(data.message != null){
        commit("setSuccess", data.message, { root: true });
      }
      commit("setPostCheck", true, { root: true });
      setTimeout(() => {
        commit("setPostCheck", false, { root: true });
      }, 800);
      if (data.redirect) {
        setTimeout(() => {
          router.push({ name: data.redirect });
        }, 2500);
      }
    } else if (data.server.data && data.server.data.message != "") {
      commit("setError", data.server.data.message, { root: true });
      commit("setPostCheck", false, { root: true });
    } else {
      commit("setError", messages.alert.error.server, { root: true });
      commit("setPostCheck", false, { root: true });
    }
  },

  successPOSTHandlerSimple({ commit }, data) {
    if (data.server && data.server.status === 200) {
      commit("setSuccess", data.message, { root: true });
    } else if (data.server.data && data.server.data.message != "") {
      commit("setError", data.server.data.message, { root: true });
    } else {
      commit("setError", messages.alert.error.server, { root: true });
    }
  },

  errorPOSTHandler({ dispatch, commit }, data) {
    commit("setPostCheck", false, { root: true });
    if (data.response && data.response.status === 403) {
      commit("setError", messages.alert.error.access, { root: true });
    } else if (data.response && data.response.status === 401) {
      dispatch("logout", null, { root: true });
    } else if (data.response.data.message && data.response.data.message != "") {
      commit("setError", data.response.data.message, { root: true });
    } else {
      commit("setError", messages.alert.error.server, { root: true });
    }
  },

  errorGETHandler({ dispatch, commit }, data) {
    commit("setPostCheck", false, { root: true });
    if (data.response && data.response.status === 403) {
      router.push({ name: "Page403" });
    } else if (data.response && data.response.status === 401) {
      dispatch("logout", null, { root: true });
    } else if (data.response.data.message && data.response.data.message != "") {
      commit("setError", data.response.data.message, { root: true });
    } else {
      commit("setError", messages.alert.error.server, { root: true });
    }
  },
};
