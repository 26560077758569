import Vue from 'vue'
import VueRouter from 'vue-router'
import GlobalGuards from "@/guards/Global";
import TheCustomerContainer from "@/containers/customer/TheContainer";
import TheOperatorContainer from "@/containers/operator/TheContainer";
const Page404 = () => import("@/views/errors/Page404");
const Page403 = () => import("@/views/errors/Page403");
const Page500 = () => import("@/views/errors/Page500");
const PageUnderConstruction = () => import("@/views/errors/PageUnderConstruction");

Vue.use(VueRouter)

const routes = [

// Customer Routes
  {
    path: "/",
    redirect: "/customer/dashboard",
  },

  {
    path: "/customer",
    name: "CustomerContainer",
    component: TheCustomerContainer,
    redirect: "/customer/dashboard",
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: {
          label: 'Dashboard',
          requiresAuth: true,
        },
        component: () => import('@/views/customer/Dashboard'),
      },
      {
        path: 'profile',
        name: 'CustomerProfile',
        meta: {
          label: 'Profile',
          requiresOperatorAuth: true,
        },
        component: () => import('@/views/auth/Profile'),
      },
      {
        path: 'about',
        name: 'About',
        meta: {
          label: 'About Us',
          requiresAuth: true,
        },
        component: () => import('@/views/customer/AboutView'),
      },
      {
        path: 'registration',
        name: 'RegistrationForm',
        meta: {
          label: 'Registration form',
          requiresAuth: true,
        },
        component: () => import('@/views/customer/Registration'),
      },
      {
        path: 'support',
        name: 'CustomerSupport',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/customer/support/list',
        children: [
          {
            path: 'list',
            name: 'CustomerSupportList',
            component: () => import('@/views/customer/support/index'),
            meta: {
              label: 'Support',
              requiresAuth: true
            }
          },
          {
            path: 'add',
            name: 'AddCustomerSupport',
            component: () => import('@/views/customer/support/Add'),
            meta: {
              label: 'Open New Ticket',
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: 'orders',
        name: 'CustomerOrders',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/customer/orders/list',
        children: [
          {
            path: 'list',
            name: 'OrderList',
            component: () => import('@/views/customer/orders'),
            meta: {
              label: 'Orders',
              requiresAuth: true
            }
          },
          {
            path: 'enquiry/list',
            name: 'OrderEnquiries',
            component: () => import('@/views/customer/orders/enquiry'),
            meta: {
              label: 'Order Enquiries',
              requiresAuth: true
            }
          },
          {
            path: 'enquiry/add',
            name: 'AddOrderEnquiry',
            component: () => import('@/views/customer/orders/enquiry/Add'),
            meta: {
              label: 'Add New Order Enquiry',
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: 'rejected',
        name: 'RejectedTemplate',
        meta: {
          label: 'Rejected Template Message',
          requiresAuth: true,
        },
        component: () => import('@/views/customer/RejectedTemplate'),
      },
    ],
  },


// Operator Routes  -------------------------------------------------------------------------------------
  {
    path: "/operator",
    name: "OperatorContainer",
    component: TheOperatorContainer,
    redirect: "/operator/dashboard",
    children: [
      {
        path: 'dashboard',
        name: 'OperatorDashboard',
        meta: {
          label: 'Dashboard',
          requiresOperatorAuth: true,
        },
        component: () => import('@/views/operator/Dashboard'),
      },
      {
        path: 'profile',
        name: 'OperatorProfile',
        meta: {
          label: 'Profile',
          requiresOperatorAuth: true,
        },
        component: () => import('@/views/auth/Profile'),
      },
      {
        path: 'sbu',
        name: 'ManageSBU',
        meta: {
          label: 'SBU and Departments',
          requiresOperatorAuth: true,
        },
        component: () => import('@/views/operator/sbu'),
      },
      {
        path: 'otp',
        name: 'ManageOTP',
        meta: {
          label: 'Signup OTP code',
          requiresOperatorAuth: true,
        },
        component: () => import('@/views/operator/OTP'),
      },
      {
        path: 'users',
        name: 'ManageUsers',
        meta: {
          label: 'User Management',
          requiresOperatorAuth: true,
        },
        component: () => import('@/views/operator/Users'),
      },
      {
        path: 'registration',
        name: 'RegistrationRequests',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/operator/registration/requests',
        children: [
          {
            path: 'requests',
            name: 'RegistrationRequestList',
            component: () => import('@/views/operator/registrations'),
            meta: {
              label: 'Registration Requests',
              requiresOperatorAuth: true
            }
          },
          {
            path: 'add',
            name: 'AddRegistrationRequest',
            component: () => import('@/views/operator/registrations/Add'),
            meta: {
              label: 'Add Registration Request',
              requiresOperatorAuth: true
            }
          },
          {
            path: 'edit/:id',
            name: 'EditRegistrationRequest',
            component: () => import('@/views/operator/registrations/Edit'),
            meta: {
              label: 'Edit Registration Request',
              requiresOperatorAuth: true
            }
          },
        ]
      },
      {
        path: 'customers',
        name: 'ManageCustomers',
        meta: {
          label: 'Customers Management',
          requiresOperatorAuth: true,
        },
        component: () => import('@/views/operator/customers/index'),
      },
      {
        path: 'support',
        name: 'OperatorSupport',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/operator/support/list',
        children: [
          {
            path: 'list',
            name: 'OperatorSupportList',
            component: () => import('@/views/operator/support'),
            meta: {
              label: 'Support',
              requiresOperatorAuth: true
            }
          },
          {
            path: 'add',
            name: 'AddOperatorSupport',
            component: () => import('@/views/operator/support/Add'),
            meta: {
              label: 'Open New Ticket',
              requiresOperatorAuth: true
            }
          },
        ]
      },
      {
        path: 'orders',
        name: 'OperatorOrders',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: '/operator/orders/list',
        children: [
          {
            path: 'list',
            name: 'OperatorOrderList',
            component: () => import('@/views/operator/orders'),
            meta: {
              label: 'Orders List',
              requiresOperatorAuth: true
            }
          },
          {
            path: 'add',
            name: 'OperatorAddOrder',
            component: () => import('@/views/operator/orders/Add'),
            meta: {
              label: 'Add Order',
              requiresOperatorAuth: true
            }
          },
          {
            path: 'edit/:id',
            name: 'OperatorEditOrder',
            component: () => import('@/views/operator/orders/Edit'),
            meta: {
              label: 'Edit Order',
              requiresOperatorAuth: true
            }
          },
          {
            path: 'enquiries',
            name: 'OperatorOrderEnquiries',
            component: () => import('@/views/operator/orders/enquiry'),
            meta: {
              label: 'Order Enquiries',
              requiresOperatorAuth: true
            }
          },
          {
            path: 'enquiries/add',
            name: 'OperatorAddOrderEnquiry',
            component: () => import('@/views/operator/orders/enquiry/Add'),
            meta: {
              label: 'Add Order Enquiry',
              requiresOperatorAuth: true
            }
          },
          {
            path: 'BAFA',
            name: 'OperatorBAFAOrder',
            component: () => import('@/views/operator/orders/Bafa'),
            meta: {
              label: 'Export permit list',
              requiresOperatorAuth: true
            }
          },
        ]
      },
    ]
  },

  // other Pages - without container
  {
    path: "/customer/signin",
    name: "CustomerLogin",
    meta: {
      label: "Sign in",
    },
    component: () => import("@/views/auth/CustomerLogin"),
    beforeEnter(to, from, next) {
      GlobalGuards.login(to, from, next);
    },
  },
  {
    path: "/customer/signup",
    name: "CustomerRegister",
    meta: {
      label: "Sign up",
    },
    component: () => import("@/views/auth/CustomerRegister"),
    beforeEnter(to, from, next) {
      GlobalGuards.login(to, from, next);
    },
  },
  {
    path: "/operator/login",
    name: "OperatorLogin",
    meta: {
      label: "Management Login",
    },
    component: () => import("@/views/auth/OperatorLogin"),
    beforeEnter(to, from, next) {
      GlobalGuards.login(to, from, next);
    },
  },

  {
    path: "/pages",
    redirect: "/pages/404",
    name: "Pages",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "404",
        name: "Page404",
        component: Page404,
      },
      {
        path: "403",
        name: "Page403",
        component: Page403,
      },
      {
        path: "500",
        name: "Page500",
        component: Page500,
      },
      {
        path: "underConstruction",
        name: "PageUnderConstruction",
        component: PageUnderConstruction,
      },
    ],
  },

  {
    path: "*",
    component: Page404,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, from, next) => GlobalGuards.beforeEach(to, from, next));
router.afterEach((to, from) => GlobalGuards.afterEach(to, from));

export default router
