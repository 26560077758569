import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import en from 'vuetify/lib/locale/en';
import fa from 'vuetify/lib/locale/fa';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, fa },
    current: 'en',
  },
  rtl: false,
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      default: 'light',
      light: {
        primary: '#007BFF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        primary: '#007BFF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
    icons: {
    iconfont: 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
  },
});
