export default {

  registrations: (state) => {
    return state.registrations;
  },

  registration: (state) => {
    return state.registration;
  },

  registrationFiles: (state) => {
    return state.registrationFiles;
  },

  messages: (state) => {
    return state.messages;
  },
  closedMessages: (state) => {
    return state.closedMessages;
  },
  message: (state) => {
    return state.message;
  },

  notifications: (state) => {
    return state.notifications;
  },

  splResult: (state) => {
    return state.splResult;
  },

};
