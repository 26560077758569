import axios from 'axios'
import * as messages from '@/plugins/AlertMessages.js'

export default {

  getOrderEnquiries({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: "Loading ..." },
      { root: true }
    );
    axios
      .get("order/enquiry")
      .then((res) => {
        commit("setOrderEnquiries", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getOrderEnquiry({ dispatch, commit }, data) {
    axios
      .get("order/enquiry/" + data)
      .then((res) => {
        commit("setOrderEnquiry", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getOrderEnquiryDetails({ dispatch, commit }, data) {
    axios
      .get("order/enquiry/details/" + data)
      .then((res) => {
        commit("setOrderEnquiryDetails", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addOrderEnquiry({ dispatch }, data) {
    axios
      .post("order/enquiry", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  setOrderPPLCheck({ dispatch }, data) {
    axios
      .put("order/enquiry/ppl/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  deleteOrderEnquiry({ dispatch }, data) {
    axios
      .delete("order/enquiry/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  getOrderQuotation({ dispatch, commit }, data) {
    axios
      .get("order/quotation/" + data)
      .then((res) => {
        commit("setOrderQuotation", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addOrderQuotation({ dispatch }, data) {
    axios
      .post("order/quotation", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  setOrderQuotationStatus({ dispatch }, data) {
    axios
      .put("order/quotation/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.changeStatus,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editOrderQuotation({ dispatch }, data) {
    axios
      .put("order/quotation/update/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  getOrders({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: "Loading ..." },
      { root: true }
    );
    axios
      .get("order")
      .then((res) => {
        commit("setOrders", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getOrder({ dispatch, commit }, data) {
    axios
      .get("order/" + data)
      .then((res) => {
        commit("setOrder", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getOrderDetails({ dispatch, commit }, data) {
    axios
      .get("order/details/" + data)
      .then((res) => {
        commit("setOrderDetails", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addOrderDetail({ dispatch }, data) {
    axios
      .post("order/details", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editOrderDetail({ dispatch }, data) {
    axios
      .put("order/details/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  deleteOrderDetail({ dispatch }, data) {
    axios
      .delete("order/details/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  addOrder({ dispatch }, data) {
    axios
      .post("order", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  updateOrder({ dispatch }, data) {
    axios
      .put("order/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  deleteOrder({ dispatch }, data) {
    axios
      .delete("order/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  setOrderDetailPPLCheck({ dispatch }, data) {
    axios
      .put("order/ppl/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  uploadProformaInvoice({ dispatch }, data) {
    axios
      .post("order/proforma/" + data.id, data.value, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.upload,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  changeOrderStatus({ dispatch }, data) {
    axios
      .put("order/status/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },


  cancelOrder({ dispatch }, data) {
    axios
      .delete("order/cancel/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.cancel,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },




// Incoterms and Payment Terms Actions ----------------------------------------------
  getIncoterms({ dispatch, commit }) {
    axios
      .get("incoterms")
      .then((res) => {
        commit("setIncoterms", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getPaymentTerms({ dispatch, commit }) {
    axios
      .get("paymentTerms")
      .then((res) => {
        commit("setPaymentTerms", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },


// WebForm Application API Actions ----------------------------------------------
  getWebformProducts({ dispatch, commit }) {
    axios
      .get("webform/products")
      .then((res) => {
        commit("setWebformProducts", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getWebformProduct({ dispatch, commit }, data) {
    axios
      .get("webform/products/" + data)
      .then((res) => {
        commit("setWebformProduct", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getWebformCustomers({ dispatch, commit }) {
    axios
      .get("webform/customers")
      .then((res) => {
        commit("setWebformCustomers", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getWebformCustomersSoldTo({ dispatch, commit }) {
    axios
      .get("webform/customers?type=sold_to")
      .then((res) => {
        commit("setWebformCustomersSoldTo", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getWebformCustomersShipTo({ dispatch, commit }) {
    axios
      .get("webform/customers?type=ship_to")
      .then((res) => {
        commit("setWebformCustomersShipTo", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getWebformCustomersEndTo({ dispatch, commit }) {
    axios
      .get("webform/customers")
      .then((res) => {
        commit("setWebformCustomersEndTo", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getWebformShippers({ dispatch, commit }) {
    axios
      .get("webform/shippers")
      .then((res) => {
        commit("setWebformShippers", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getWebformSells({ dispatch, commit }) {
    axios
      .get("webform/sells")
      .then((res) => {
        commit("setWebformSells", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // PPL Automation Actions -------------------------------------------------------
  checkPPLAutomatic({ dispatch, commit }, data) {
    axios
      .get("chemical-product/status?order_detail_id=" + data)
      .then((res) => {
        commit("setPplResult", res.data.data);
        commit("setInfo", res.data.message, {root: true});
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
  },

  getBafaList({ dispatch, commit }) {
    axios
      .get("bafa")
      .then((res) => {
        commit("setBafaList", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  updateBafa({ dispatch, commit }, data) {
    axios
      .put("bafa/" + data.id + "?" + data.status)
      .then((res) => {
        if(res.data.message) {
          commit("setError", res.data.message, {root: true});
        }else {
          commit("setSuccess", messages.alert.success.changeStatus, {root: true});
        }
        commit("setPostCheck", true, { root: true });
        setTimeout(() => {
          commit("setPostCheck", false, { root: true });
        }, 800);
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  uploadPplExcel({ commit, dispatch }, data) {
    axios
      .post("chemical-product/import", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.upload,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        commit("setPostCheck", true, { root: true });
        setTimeout(() => {
          commit("setPostCheck", false, { root: true });
        }, 800);
      });
  },


  getPplFileLastVersion({ dispatch, commit }) {
    axios
      .get("chemical-product/latest-version")
      .then((res) => {
        commit("setPplFileVersion", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },


};
