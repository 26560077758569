export default {
  token: null,
  loginCheck: false,
  forgotCheck: false,
  forgotBtn: false,
  userProfile: {},

  postCheck: false,
  checkAlert: 0,
  successMessage: null,
  warningMessage: null,
  infoMessage: null,
  dangerMessage: null,

  loadingCheck: 0,
  loadingMessage: "Loading ...",
};
