export default {

  setUsers(state, data) {
    state.users = data;
  },
  setSecondUsers(state, data) {
    state.secondUsers = data;
  },
  setUser(state, data) {
    state.user = data;
  },

  setCustomers(state, data) {
    state.customers = data;
  },
  setCustomer(state, data) {
    state.customer = data;
  },
  setAllCustomers(state, data) {
    state.allCustomers = data;
  },
  setUnapprovedCustomers(state, data) {
    state.unapprovedCustomers = data;
  },

  setDepartments(state, data) {
    state.departments = data;
  },

  setSbuList(state, data) {
    state.sbuList = data;
  },
  setSbu(state, data) {
    state.sbu = data;
  },

  setRegisterOTPCodes(state, data) {
    state.registerOTPCodeList = data;
  },
  setRegisterOTPCode(state, data) {
    state.registerOTPCode = data;
  },

};
