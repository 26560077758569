export default {

  setOrderEnquiries(state, data) {
    state.orderEnquiries = data;
  },

  setOrderEnquiry(state, data) {
    state.orderEnquiry = data;
  },

  setOrderEnquiryDetails(state, data) {
    state.orderEnquiryDetails = data;
  },

  setOrderQuotation(state, data) {
    state.orderQuotation = data;
  },

  setOrders(state, data) {
    state.orders = data;
  },

  setOrder(state, data) {
    state.order = data;
  },

  setOrderDetails(state, data) {
    state.orderDetails = data;
  },

  setIncoterms(state, data) {
    state.incoterms = data;
  },

  setPaymentTerms(state, data) {
    state.paymentTerms = data;
  },

  setWebformProducts(state, data) {
    state.webformProducts = data;
  },
  setWebformProduct(state, data) {
    state.webformProduct = data;
  },

  setWebformCustomers(state, data) {
    state.webformCustomers = data;
  },
  setWebformCustomersSoldTo(state, data) {
    state.webformCustomersSoldTo = data;
  },
  setWebformCustomersShipTo(state, data) {
    state.webformCustomersShipTo = data;
  },
  setWebformCustomersEndTo(state, data) {
    state.webformCustomersEndTo = data;
  },

  setWebformShippers(state, data) {
    state.webformShippers = data;
  },

  setWebformSells(state, data) {
    state.webformSells = data;
  },

  setPplResult(state, data) {
    state.pplResult = data;
  },

  setBafaList(state, data) {
    state.bafaList = data;
  },

  setPplFileVersion(state, data) {
    state.pplFileVersion = data;
  },

};
