import axios from "axios";
import * as messages from "@/plugins/AlertMessages.js";

export default {

// Users Actions ------------------------------------------------------------------
  getUsers({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: "Loading ..." },
      { root: true }
    );
    axios
      .get("users")
      .then((res) => {
        commit("setUsers", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getUsersByDepartment({ dispatch, commit }, data) {
    axios
      .get("users/department/" + data)
      .then((res) => {
        commit("setUsers", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getSecondUsersByDepartment({ dispatch, commit }, data) {
    axios
      .get("users/department/" + data)
      .then((res) => {
        commit("setSecondUsers", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getUser({ dispatch, commit }, data) {
    axios
      .get("users/" + data)
      .then((res) => {
        commit("setUser", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
  },

  addUser({ dispatch }, data) {
    axios
      .post("users", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.addUser,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editUser({ dispatch }, data) {
    axios
      .put("users/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.editUser,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  changeUserStatus({ dispatch }, data) {
    axios
      .put("users/status/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.changeStatus,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  resetUserPassword({ commit, dispatch }, data) {
    commit(
      "setLoading",
      { status: 1, message: "Connecting to mail server ..." },
      { root: true }
    );
    axios
      .put("users/resetPassword/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.resetPassword,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  deleteUser({ dispatch }, data) {
    axios
      .delete("users/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.deleteUser,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

// Customers Actions ------------------------------------------------------------------
  getAllCustomers({ dispatch, commit }) {
    axios
      .get("customers/all")
      .then((res) => {
        commit("setAllCustomers", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getCustomers({ dispatch, commit }) {
    axios
      .get("customers")
      .then((res) => {
        commit("setCustomers", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getUnapprovedCustomers({ dispatch, commit }) {
    axios
      .get("customers/unapproved")
      .then((res) => {
        commit("setUnapprovedCustomers", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getCustomer({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: "Loading ..." },
      { root: true }
    );
    axios
      .get("customers/" + data)
      .then((res) => {
        commit("setCustomer", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      })
  },

  // Departments and SBU Actions -------------------------------------------------------------
  getDepartments({ dispatch, commit }) {
    axios
      .get("departments")
      .then((res) => {
        commit("setDepartments", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getSbuList({ dispatch, commit }) {
    axios
      .get("sbu")
      .then((res) => {
        commit("setSbuList", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getSbu({ dispatch, commit }, data) {
    axios
      .get("sbu/" + data)
      .then((res) => {
        commit("setSbu", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addSbu({ dispatch }, data) {
    axios
      .post("sbu", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editSbu({ dispatch }, data) {
    axios
      .put("sbu/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  deleteSbu({ dispatch }, data) {
    axios
      .delete("sbu/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

// Register OTP Code Action ------------------------------------------------------
  getRegisterOTPCodes({ dispatch, commit }) {
    axios
      .get("registerOTP")
      .then((res) => {
        commit("setRegisterOTPCodes", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  generateRegisterOTPCode({ commit, dispatch }, data) {
    axios
      .post("registerOTP", data)
      .then((res) => {
        commit("setRegisterOTPCode", res.data.data.code);
        dispatch(
          "successPOSTHandlerSimple",
          {
            server: res,
            message: messages.alert.success.generateCode,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  deleteRegisterOTPCode({ dispatch }, data) {
    axios
      .delete("registerOTP/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: messages.alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },


};
