<template>
  <router-view />
</template>

<script>
import "vue-toastification/dist/index.css";

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style lang="sass">
@import "@/assets/styles/style"
</style>
