<template>
  <div class="header">

    <v-navigation-drawer
      v-model="drawer"
      app
      absolute
      width="290"
      class="sidebar customer-sidebar"
    >

      <div class="sidebar__avatar-wrapper">
        <div class="">
          <v-avatar size="70">
            <img
              :src="profile.avatarPath"
              :alt="profile.name"
            >
          </v-avatar>
          <h2>
            {{ profile.name }}
          </h2>
          <span>
            <i class="fas fa-id-card-alt"></i>
            {{ profile.userName }}
          </span>
        </div>
      </div>

      <router-link
        :to="{ name: 'Dashboard' }"
        class="nav-item hvr-sweep-to-right"
      >
        <i class="fas fa-home"></i>
          Dashboard
      </router-link>

      <router-link
        :to="{ name: 'RegistrationForm' }"
        class="nav-item hvr-sweep-to-right"
      >
        <i class="fas fa-user-plus"></i>
          Register Form
      </router-link>

      <!-- <router-link
        :to="{ name: 'OrderEnquiries' }"
        class="nav-item hvr-sweep-to-right"
      >
        <i class="fas fa-shopping-bag"></i>
          Order Enquiries
      </router-link> -->

      <router-link
        :to="{ name: 'OrderList' }"
        class="nav-item hvr-sweep-to-right"
      >
        <i class="fas fa-shopping-cart"></i>
          Orders
      </router-link>

      <router-link
        :to="{ name: 'CustomerSupport' }"
        class="nav-item hvr-sweep-to-right"
      >
        <i class="far fa-comment-dots"></i>
          Support
      </router-link>

    </v-navigation-drawer>



    <v-app-bar
      flat
      color="white"
      app
    >

    <v-btn
      @click="toggleSidebar()"
      class="drawer-hide"
      elevation="0"
      fab
      v-if="drawer"
    ><i class="fas fa-chevron-left"></i>
    </v-btn>
    <v-btn
      @click="toggleSidebar()"
      class="drawer-show"
      elevation="0"
      fab
      v-else
    ><i class="fas fa-bars"></i>
    </v-btn>

      <v-toolbar-title>
        <v-img
          :src="require('@/assets/images/logo.png')"
          class="header-logo"
          contain
        />
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu
        bottom
        rounded
        transition="scale-transition"
        nudge-bottom="6"
        max-width="300"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn icon class="mr-2" v-on="on">
            <v-badge
              bordered
              top
              left
              color="second"
              dot
              offset-x="28"
              offset-y="23"
              v-if="messages.length > 0"
            >
              <v-icon>mdi-email-outline</v-icon>
            </v-badge>
            <v-icon v-else>
              mdi-email-outline
            </v-icon>
          </v-btn>
        </template>
        <v-list>
        <v-list-item
          class="msg-list-item"
          v-for="(message, index) in messages.slice(0, 5)"
          :key="index"
        >
          <v-list-item-title>
            <v-icon size="8" color="second" class="me-1">mdi-circle</v-icon>
            {{ message.subject }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          class="p-fs-n"
          v-if="messages && messages.length === 0"
        >
          there is no new messages
        </v-list-item>
        <v-list-item
        >
          <v-btn
            text
            small
            block
            :to="{name: 'CustomerSupportList'}"
          >
            <v-icon small class="me-1">mdi-email-open-outline</v-icon>
            see all messages
          </v-btn>
        </v-list-item>
      </v-list>
      </v-menu>

      <v-menu
        bottom
        rounded
        offset-y
        nudge-bottom="6"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            x-large
            v-on="on"
            class="mr-12"
          >
            <v-badge
              bordered
              bottom
              color="success"
              dot
              offset-x="10"
              offset-y="10"
            >
              <v-avatar
                color="brown"
                size="40"
              >
                <img
                :src="profile.avatarPath"
                :alt="profile.name"
                >
              </v-avatar>
            </v-badge>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="px-3 text-center">
              <h5>{{ profile.name }}</h5>
              <p class="p-fs-s mt-1">
                {{ profile.email }}
              </p>
              <v-divider class="my-1"></v-divider>
              <v-btn
                text
                small
                block
                :to="{name: 'CustomerProfile'}"
              >
                Edit Account
              </v-btn>
              <v-divider class="my-1"></v-divider>
              <v-btn
                text
                small
                block
                color="error"
                @click="logout"
              >
              <v-icon small class="mr-1">mdi-power</v-icon>
                logout
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>

    </v-app-bar>


  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "TheCustomerHeader",
  data() {
    return{
      drawer: null,
    }
  },
  computed: {
    ...mapGetters({
      profile: 'userProfile',
      messages: 'operationX/messages',
    }),
  },
  watch: {
    profile(){
      if(this.profile && this.profile.departmentKey === 'customer'){
        this.$store.dispatch('baseX/getCustomer', this.profile.id);
      } else {
        this.$router.push({name: 'Page403'});
      }
    },
  },
  methods: {
    toggleSidebar(){
      this.drawer = !this.drawer
      var sidebar = document.getElementById("sidebar");
      var content = document.getElementById("main");
      var wrapper = document.getElementById("sidebar-wrapper");
      var toggler = document.getElementById("sidebar-toggler");
      wrapper.classList.toggle('hideEle');
        if (sidebar.style.width === "0%") {
          sidebar.style.width = "17%";
          content.style.width = "83%";
          toggler.style.marginRight = "-20px";

        } else {
          sidebar.style.width = "0%";
          content.style.width = "100%";
          toggler.style.marginRight = "10px";
        }
    },
    logout() {
      this.$store.dispatch("logoutCustomer");
    },
  },
  beforeCreate() {
    this.$store.dispatch('getCurrentUser');
    this.$store.dispatch('operationX/getMessages');
  },
};
</script>
