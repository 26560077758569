<template>
  <div v-if="loadingCheck">
    <div class="loading-container">
      <v-progress-circular
        :size="50"
        color="white"
        indeterminate
      ></v-progress-circular>
      <h4>{{ loadingMessage }}</h4>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TheLoading",
  data() {
    return {
      loading: null,
    };
  },
  computed: {
    ...mapState({
      loadingCheck: "loadingCheck",
      loadingMessage: "loadingMessage",
    }),
  },
};
</script>
