export default {

  users: (state) => {
    return state.users;
  },
  user: (state) => {
    return state.user;
  },
  userOptions: (state) => {
    const userOptions = [];
    state.users.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.name,
      };
      userOptions.push(pushItem);
    }
    return userOptions;
  },
  secondUserOptions: (state) => {
    const userOptions = [];
    state.secondUsers.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.name,
      };
      userOptions.push(pushItem);
    }
    return userOptions;
  },

  customers: (state) => {
    return state.customers;
  },
  customer: (state) => {
    return state.customer;
  },
  customerOptions: (state) => {
    const customerOptions = [];
    state.customers.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.registration.companyName + " (" + item.name + ")",
      };
      customerOptions.push(pushItem);
    }
    return customerOptions;
  },
  allCustomers: (state) => {
    return state.allCustomers;
  },
  allCustomerOptions: (state) => {
    const customerOptions = [];
    state.allCustomers.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.name,
      };
      customerOptions.push(pushItem);
    }
    return customerOptions;
  },
  unapprovedCustomers: (state) => {
    return state.unapprovedCustomers;
  },
  unapprovedCustomerOptions: (state) => {
    const customerOptions = [];
    state.unapprovedCustomers.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.name,
      };
      customerOptions.push(pushItem);
    }
    return customerOptions;
  },

  departments: (state) => {
    return state.departments;
  },
  departmentOptions: (state) => {
    const departmentOptions = [];
    state.departments.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.title,
        key: item.keyword,
      };
      departmentOptions.push(pushItem);
    }
    return departmentOptions;
  },

  sbuList: (state) => {
    return state.sbuList;
  },
  sbu: (state) => {
    return state.sbu;
  },
  sbuOptions: (state) => {
    const sbuOptions = [];
    state.sbuList.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.sbu + " (" + item.dc + ")",
        dc: item.dc,
      };
      sbuOptions.push(pushItem);
    }
    return sbuOptions;
  },

  registerOTPCodeList: state => {
    return state.registerOTPCodeList;
  },
  registerOTPCode: state => {
    return state.registerOTPCode;
  },


  // Static Getters ---------------------------------------------------------------
  fieldsPlanOptions() {
    return [
      { value: "A", text: "Plan A" },
      { value: "B", text: "Plan B" },
      { value: "C", text: "Plan C" },
      { value: "D", text: "Plan D" },
    ];
  },

};
