// Global Guards
/* eslint-disable */

const beforeEach = (to, from, next) => {
  var usertoken = JSON.stringify(window.localStorage.getItem("token"));
  const authUser = JSON.parse(usertoken);
  if (to.meta.requiresAuth) {
    if (authUser) {
      next();
    } else {
      next({ name: "CustomerLogin" });
    }
  }
  else if (to.meta.requiresOperatorAuth) {
    if (authUser) {
      next();
    } else {
      next({ name: "OperatorLogin" });
    }
  }
  next();
};

const login = (to, from, next) => {
  if (localStorage.getItem('token')) {
    next({name: 'Dashboard'})
  }
  next()
};

const afterEach = (to, from) => {
  // Put your guard actions here
};

export default {
  beforeEach,
  login,
  afterEach,
};
