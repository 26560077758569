export default {

  setRegistrations(state, data) {
    state.registrations = data;
  },

  setRegistration(state, data) {
    state.registration = data;
  },

  setRegistrationFiles(state, data) {
    state.registrationFiles = data;
  },

  setMessages(state, data) {
    state.messages = data;
  },
  setClosedMessages(state, data) {
    state.closedMessages = data;
  },
  setMessage(state, data) {
    state.message = data;
  },

  setNotifications(state, data) {
    state.notifications = data;
  },

  setSplResult(state, data) {
    state.splResult = data;
  },


};
