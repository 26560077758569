export let alert = {
  success: {
    save: "Success, Your data was saved",
    submit: "The form has been submitted successfully",
    edit: "Edited successfully",
    addUser: "User Account Created successfully",
    editUser: "the User's information Edited successfully",
    delete: "The item has been deleted successfully",
    deleteUser: "The User has been deleted successfully",
    login: "Welcome to BASF Application ...",
    register: "Congratulations, your account has been created",
    changeStatus: "The status successfully changed",
    changeAvatar: "Your profile image was updated",
    editProfile: "Your profile has been updated",
    changePassword: "Success, Your password has been changed",
    resetPassword: "Success, the password has been Reset",
    forgotPassword: "Your Password has been Reset and Sent to Your Email Address",
    upload: "Your File has been Uploaded",
    generateCode: "the OTP Code has been Generated",
    closeMessage: "your Message has been Closed",
    cancel: "The item has been canceled successfully",
  },
  error: {
    access: "Sorry, you don't have access",
    notFound: "The data was not found",
    server: "Could not connect to server",
    emptyFields: "please fill the required items",
    rePassword: "the password repeat does not match with password",
    closeTicket: "you dont have access to close this ticket",
    delete: "you dont have access to Delete this item",
    orderDetailsItem: "The Order must have at least one item",
    pplCheck: "All Articles must have positive PPL",
  },
};
