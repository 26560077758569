<template>
  <v-app>

    <the-header />

    <the-alerts />

    <v-main>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"></router-view>
      </transition>
    </v-main>

    <the-loading />

  </v-app>
</template>

<script>
import TheHeader from "@/containers/customer/TheHeader";
import TheAlerts from "@/components/TheAlerts";
import TheLoading from "@/components/TheLoading";

export default {
  name: "TheCustomerContainer",
  components: {
    TheHeader,
    TheAlerts,
    TheLoading,
  },
  methods: {},
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
