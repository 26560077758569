export default {

  orderEnquiries: [],
  orderEnquiry: {},
  orderEnquiryDetails: [],
  orderQuotation: {},
  orders: [],
  order: {},
  orderDetails: [],

  incoterms: [],
  paymentTerms: [],

  webformProducts: [],
  webformProduct: {},
  webformCustomers: [],
  webformCustomersSoldTo: [],
  webformCustomersShipTo: [],
  webformCustomersEndTo: [],
  webformShippers: [],
  webformSells: [],

  pplResult: null,
  bafaList: [],
  pplFileVersion: {},

};
