import Vue from "vue";

// seprate Currency with camma
Vue.filter("localNum", function (value) {
  if (!value) return "";
  value = Number(value);
  return value.toLocaleString("EN");
});

// set Sizes from Bytes to KB
Vue.filter("roundFileSize", function (value) {
  let item = value / 1000;
  item = Math.round(item);
  if (item === 0) {
    item = 1;
  }
  return item;
});

// set limit to characters (Strings)
Vue.filter("charactersLimit", function (value, size) {
  if (!value) return "";
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + "...";
});
