export default {

  orderEnquiries: (state) => {
    return state.orderEnquiries;
  },
  orderEnquiry: (state) => {
    return state.orderEnquiry;
  },
  orderEnquiryDetails: (state) => {
    return state.orderEnquiryDetails;
  },
  orderQuotation: (state) => {
    return state.orderQuotation;
  },
  orders: (state) => {
    return state.orders;
  },
  order: (state) => {
    return state.order;
  },
  orderDetails: (state) => {
    return state.orderDetails;
  },


  incoterms: (state) => {
    return state.incoterms;
  },
  incotermOptions: (state) => {
    const incotermOptions = [];
    state.incoterms.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.title,
      };
      incotermOptions.push(pushItem);
    }
    return incotermOptions;
  },

  paymentTerms: (state) => {
    return state.paymentTerms;
  },
  paymentTermOptions: (state) => {
    const paymentTermOptions = [];
    state.paymentTerms.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.title,
      };
      paymentTermOptions.push(pushItem);
    }
    return paymentTermOptions;
  },

  webformProducts: (state) => {
    return state.webformProducts;
  },
  webformProduct: (state) => {
    return state.webformProduct;
  },
  webformProductOptions: (state) => {
    const productOptions = [];
    state.webformProducts.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.article,
        text: item.article + " - " + item.name + " - " + item.package,
        origin: item.origin,
        package: item.package,
      };
      productOptions.push(pushItem);
    }
    return productOptions;
  },
  webformEnquiryProductOptions: (state) => {
    const productOptions = [];
    state.webformProducts.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.article + " - " + item.name,
        text: item.article + " - " + item.name + " - " + item.package,
      };
      productOptions.push(pushItem);
    }
    return productOptions;
  },

  webformCustomers: (state) => {
    return state.webformCustomers;
  },
  webformCustomerOptions: (state) => {
    const customerOptions = [];
    state.webformCustomers.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.custnum + " - " + item.company + " - " + item.type,
        text: item.custnum + " - " + item.company + " - " + item.type,
      };
      customerOptions.push(pushItem);
    }
    return customerOptions;
  },
  webformCustomersSoldTo: (state) => {
    const customerOptions = [];
    state.webformCustomersSoldTo.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.custnum + " - " + item.company + " - " + item.type,
        text: item.custnum + " - " + item.company + " - " + item.type,
      };
      customerOptions.push(pushItem);
    }
    return customerOptions;
  },
  webformCustomersShipTo: (state) => {
    const customerOptions = [];
    state.webformCustomersShipTo.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.custnum + " - " + item.company + " - " + item.type,
        text: item.custnum + " - " + item.company + " - " + item.type,
      };
      customerOptions.push(pushItem);
    }
    return customerOptions;
  },
  webformCustomersEndTo: (state) => {
    const customerOptions = [];
    state.webformCustomersEndTo.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.custnum + " - " + item.company + " - " + item.type,
        text: item.custnum + " - " + item.company + " - " + item.type,
      };
      customerOptions.push(pushItem);
    }
    return customerOptions;
  },

  webformShippers: (state) => {
    return state.webformShippers;
  },
  webformShipperOptions: (state) => {
    const shipperOptions = [];
    state.webformShippers.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.name,
        text: item.name,
      };
      shipperOptions.push(pushItem);
    }
    return shipperOptions;
  },

  webformSells: (state) => {
    return state.webformSells;
  },
  webformSellOptions: (state) => {
    const webformSellOptions = [];
    state.webformSells.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.partner + ' - ' + item.delivery,
        text: item.partner + ' - ' + item.delivery,
      };
      webformSellOptions.push(pushItem);
    }
    return webformSellOptions;
  },

  pplResult: (state) => {
    return state.pplResult;
  },

  bafaList: (state) => {
    return state.bafaList;
  },

  pplFileVersion: (state) => {
    return state.pplFileVersion;
  },


// Static Options -------------------------------------------------------------------

bafaOptions(){
  return [
    {
      value: 5,
      text: 'END_PENDING',
    },
    {
      value: 4,
      text: 'EXPORT_PERMIT_AVAILABILITY_RESULT',
    },
    {
      value: 1,
      text: 'EXPORT_PERMIT_REQUIREMENT_RESULT',
    },
    {
      value: 3,
      text: 'BPRF_RESULT',
    },
    {
      value: 2,
      text: 'EXPORT_PERMIT_RESULT',
    },
  ];
},

  offerValidityOptions(){
    return [
      {
        value: 30,
        text: '30 days',
      },
      {
        value: 60,
        text: '60 days',
      },
      {
        value: 90,
        text: '90 days',
      },
      {
        value: 120,
        text: '120 days',
      },
    ];
  },

  leadTimeOptions(){
    return [
      {
        value: 4,
        text: '4 weeks',
      },
      {
        value: 5,
        text: '5 weeks',
      },
      {
        value: 6,
        text: '6 weeks',
      },
      {
        value: 7,
        text: '7 weeks',
      },
      {
        value: 8,
        text: '8 weeks',
      },
    ];
  },

  transportModeOptions(){
    return [
      {
        value: 'Vessel',
        text: 'Vessel',
      },
      {
        value: 'Truck',
        text: 'Truck',
      },
      {
        value: 'Air Freight',
        text: 'Air Freight',
      },
      {
        value: 'Train',
        text: 'Train',
      },
      {
        value: 'Intermodal',
        text: 'Intermodal',
      },
    ];
  },

  saleTypeOptions(){
    return [
      {
        value: 'sold_to',
        text: 'Sold To',
      },
      {
        value: 'ship_to',
        text: 'Ship To',
      },
    ];
  },


};
