export default {

  users: [],
  secondUsers: [],
  user: {},
  customers: [],
  allCustomers: [],
  unapprovedCustomers: [],
  customer: {},

  departments: [],
  sbuList: [],
  sbu: {},

  registerOTPCodeList: [],
  registerOTPCode: null,

};
